import React, { Component } from 'react';
import styles from "./styles.module.css";

export class ComingSoon extends Component {
    render() {
        return (
            <div className={styles.backgroundRestaurant}>
                <div className={styles.backgroundWhite}>
                    <img className={styles.logo} src={require("./logo.svg")} alt='logo'/>
                    <span className={styles.logoTitleSpace}/>
                    <h1>COMING SOON</h1>
                    <span className={styles.titleDescriptionSpace}/>
                    <h3>For any inquiries feel free to contact</h3>
                    <h2><a href="mailto:info@barsolutions.hr">info@barsolutions.hr</a></h2>
                    <div className={styles.downloadSection}>
                    	<a href="https://s3.eu-central-1.amazonaws.com/barsolutions-web-main/bar_solutions.pdf" target='_blank' rel="noopener noreferrer">
                    		<button className={styles.downloadButton}>
                    			Download Business Intro
                    		</button>
                    	</a>
                    	<a href="https://s3.eu-central-1.amazonaws.com/barsolutions-web-main/tiki_thursday.pdf" target='_blank' rel="noopener noreferrer">
                    		<button className={styles.downloadButton}>
                    			Download Tiki Thursday Intro
                    		</button>
                		</a>
                		<a href="https://s3.eu-central-1.amazonaws.com/barsolutions-web-main/cocktail_catering_intro.pdf" target='_blank' rel="noopener noreferrer">
                    		<button className={styles.downloadButton}>
                    			Download Cocktail Catering Intro
                    		</button>
                    	</a>
                    	<a href="https://s3.eu-central-1.amazonaws.com/barsolutions-web-main/marketing_intro.pdf" target='_blank' rel="noopener noreferrer">
                    		<button className={styles.downloadButton}>
                    			Download Cocktail Catering Intro
                    		</button>
                    	</a>
                	</div>
                </div>
            </div>
        )
    }
}